import throttle from 'lodash.throttle';
import { createStore as reduxCreateStore } from 'redux';

import { loadState, saveState } from './local-storage';
import reducer, { initialState } from './reducer';

const createStore = () => {
  const windowGlobal = typeof window !== 'undefined' && window;

  const devtools =
    process.env.NODE_ENV === 'development' && windowGlobal.devToolsExtension
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f;

  const persistedState = loadState();
  const iniState = persistedState
    ? { ...initialState, ...persistedState }
    : initialState;

  const store = reduxCreateStore(reducer, iniState, devtools);

  store.subscribe(
    throttle(() => {
      saveState({
        currentStep: store.getState().currentStep,
        visitedSteps: store.getState().visitedSteps,
        data: store.getState().data,
      });
    }, 1000),
  );

  return store;
};

export default createStore;
